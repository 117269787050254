<template>
  <div class="content min_box" v-wechat-title="details.enterprises_name?'名企-'+ details.enterprises_name:'详情'">
    <div class="scroll_center">
      <!-- 头部 -->
      <div class="set_margin" id="头部标题-1">
        <div class="ff" id="span1">
          <top :info="{ name: details.enterprises_name }" />
        </div>
      </div>
      <div
        class="st"
        id="轮播图介绍-2"
        v-if="video_img_vr.invest_slide_img && video_img_vr.invest_slide_img.length > 0"
      >
        <!-- 轮播图 -->
        <div class="ff" id="span2">
          <swipers :list="video_img_vr.invest_slide_img" />
        </div>
      </div>
      <!-- 简介 -->
      <div class="set_margin" id="简介-3" v-if="details.e_introduction">
        <div class="ff" id="span3">
          <detailType :detail="details.e_introduction" :title="'简介'" />
        </div>
      </div>
      <!-- 基本资料 -->
      <div class="set_margin" id="基本资料-4">
        <div class="b_top ff" id="span4">
          <div class="b_top_title">
            <span class="span titles">基本资料</span>
          </div>
          <div class="basicList jianjie">
            <div class="basicItem">
              <span class="left">信用代码</span>
              <span class="span">{{
                details.basic_credit_code ? details.basic_credit_code : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">企业名称</span>
              <span class="span">{{
                details.enterprises_name ? details.enterprises_name : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">法人代表</span>
              <span class="span">{{
                details.basic_legal_deputy ? details.basic_legal_deputy : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">成立日期</span>
              <span class="span">{{
                details.basic_setup_time ? details.basic_setup_time : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">注册资本</span>
              <span class="span">{{
                details.basic_registered_capital
                  ? details.basic_registered_capital
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">企业类型</span>
              <span class="span">{{
                details.basic_enter_type ? details.basic_enter_type : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">所属行业</span>
              <span class="span">{{
                details.basic_industry ? details.basic_industry : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">所属地区</span>
              <span class="span">{{
                details.basic_enter_region ? details.basic_enter_region : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">登记机关</span>
              <span class="span">{{
                details.basic_register_depart
                  ? details.basic_register_depart
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">注册地址</span>
              <span class="span">{{
                details.basic_register_address
                  ? details.basic_register_address
                  : "暂无"
              }}</span>
            </div>
            <div class="basicItem">
              <span class="left">经营范围</span>
              <span class="span">{{
                details.basic_management ? details.basic_management : "暂无"
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 规划图 -->
      <div class="set_margin" id="规划图-5" v-if="details.enter_plan_img">
        <div class="ff" id="span5">
          <planeGuide :simages="[details.enter_plan_img]" />
        </div>
      </div>
      <!-- 视频/音频 -->
      <div
        class="set_margin"
        id="视频-6"
        v-if="video_img_vr.invest_video && video_img_vr.invest_video.length > 0"
      >
        <div class="ff" id="span6">
          <videoaudio :audios="video_img_vr.invest_video" />
        </div>
      </div>
      <!-- VR看景 -->
      <div
        class="set_margin"
        id="VR看景-7"
        v-if="video_img_vr.vrlist && video_img_vr.vrlist.length > 0"
      >
        <div class="ff" id="span7">
          <detailType :title="'VR看景'" :list="video_img_vr.vrlist" />
        </div>
      </div>
      <!-- 导航 -->
      <div
        class="set_margin"
        id="导航-8"
        v-if="details.Latitude && details.Longitude"
      >
        <div class="ff" id="span8">
          <maps :title="'导航'" :info="mapDetail" />
        </div>
      </div>
      <!-- 特色板块 -->
      <!-- 企业介绍(名企板块) -->
      <div class="set_margin" id="企业介绍-14" v-if="details.enter_introduc">
        <div class="ff" id="text14">
          <detail :name="'企业介绍'" :detail="details.enter_introduc" />
        </div>
      </div>
    </div>
    <!-- 底部导航栏 -->
    <div class="footer">
      <goodsNav :navBoj="navBoj" v-if="navBoj.phone && navBoj.name" />
    </div>
  </div>
</template>

<script>
import { gardendetail, video_img_vrs } from "@/api/http";
import top from "@/components/investmentCompoent/top.vue";
import planeGuide from "@/components/investmentCompoent/planeGuide.vue";
// import scooltop from '@/components/investmentCompoent/scooltop.vue'
import videoaudio from "@/components/investmentCompoent/videoaudio.vue";
import detail from "@/components/investmentCompoent/detail.vue";
import detailType from "@/components/investmentCompoent/detailType.vue";
import maps from "@/components/investmentCompoent/map.vue";
import swipers from "@/components/investmentCompoent/swipers.vue";
import hotel from "@/components/investmentCompoent/hotel.vue";
import goodsNav from "@/components/investmentCompoent/goodsNav.vue";
export default {
  data() {
    return {
      //高度
      scrollheight: 0,
      //锚点跳转id
      intoindex: "",
      //所有板块名称
      scorllList: [],
      details: {},
      video_img_vr: {},
      mapDetail: {},
      navBoj: {},
      //所有头部表示1,2,3
      arr: [],
      house_types: [],
      //基本资料
      info: null,
      //类型
      type: "",
      title: "投资详情",
      homeFlag: false,
      params: {},
    };
  },
  components: {
    top,
    planeGuide,
    // scooltop,
    videoaudio,
    detail,
    detailType,
    hotel,
    maps,
    swipers,
    goodsNav,
  },
  methods: {
    //点击目录进行锚点跳转
    tosloo(i) {
      //x轴锚点跳转
      this.$nextTick(() => {
        this.intoindex = "span" + this.arr[i];
      });
    },
    //点击目录回到顶部
    totop() {
      this.intoindex = "span1";
    },
  },
  created() {
    this.params = this.$route.params;
    //轮播图/全景VR/音视频
    video_img_vrs({
      dic_type: 2,
      vr_type: 1,
      id: this.params.id,
    }).then((res) => {
      this.video_img_vr = res.data;
    });
    gardendetail(
      {
        region_code: this.params.code,
        id: this.params.id,
      },
      "famouse_detail"
    ).then((res) => {
      this.details = res.data.famousen_detail;
      //地图
      this.mapDetail = {
        lat: this.details.Latitude,
        lng: this.details.Longitude,
        name: this.details.basic_enter_region,
      };
      this.navBoj = {
        name: this.details.liaison_man,
        phone: this.details.basic_phone,
      };
    });
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #eeeeee;
  .scroll_center {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 44px;
    .set_margin {
      overflow: hidden;
      margin: 6px 0;
      padding: 10px;
      background-color: #fff;
      border-radius: 16px;
      font-size: 1rem;
      box-shadow: 0 0 6px rgba(124, 124, 124, 0.2);
    }
  }
}
</style>

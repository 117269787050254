<template>
  <div class="container b_top boxs">
    <div class="b_top_title" style="position: relative">
      <span class="titles">{{ name }}</span>
    </div>
    <div class="basicList jianjie aa">
      <div class="room" v-for="item in list" :key="item.id">
        <div class="left">
          <van-swipe :autoplay="3000" indicator-color="#35a3bd" height="130">
            <van-swipe-item v-for="(item, index) in item.image" :key="item.id">
              <van-image
                @click="predivImage(item, index)"
                fit="cover"
                class="swiper_me"
                :src="item.ImgUrls"
                radius="20"
              />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="right">
          <span class="title">{{ item.ho_name }}</span>
          <div class="" style="display: flex; flex-wrap: wrap">
            <span class="span">面积：{{ item.ho_area }}平方</span>
            <span class="span">工位数：{{ item.ho_workstation }}</span>
            <span class="span">月租金：{{ item.ho_monthly_rent }}</span>
            <span class="span">类型：{{ item.ho_name }}</span>
            <span class="span">经理室：{{ item.ho_manager_room }}</span>
            <span class="span">办公设施：{{ item.ho_office_facilities }}</span>
          </div>
          <span class="price">参考价：￥{{ item.ho_reference_price }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, Image as VanImage } from "vant";
export default {
  data() {
    return {};
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
  },
  props: {
    list: {
      type: Array || Object,
      require: true,
    },
    name: {
      type: String,
      default: "户型介绍",
    },
  },
  methods: {
    predivImage(item, index) {
      let arr = [];
      item.image.forEach((v) => {
        arr.push(v.ImgUrls);
      });
    },
  },
  created() {
    // console.log(this.list);
  },
};
</script>

<style lang="less" scoped>
.jianjie {
  overflow: hidden;
}

.room {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;

  .left {
    width: 42%;
    height: 8rem;

    .swiper_me {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .right {
    margin-left: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 58%;

    .goumai {
      background-color: rgb(250, 64, 0);
      color: #fff;
      border-radius: 10px;
	  text-align: center;
      box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 3px 0px;
      width: 80%;
      font-size: .8rem;
      padding: 6px 0;
    }

    .title {
      font-weight: bold;
      font-size: 1rem;
      color: #333;
      margin-bottom: 4px;
    }

    .price {
      font-weight: 600;
      color: #ff5c59;
      font-size: 1rem;
    }

    .span {
      font-size: .6rem;
      color: #919191;
      width: 49%;
      margin-bottom: 6px;
    }
    .span:nth-child(odd) {
      margin-right: 2px;
    }
  }
}
</style>
